import React from 'react'
import { graphql, Link } from 'gatsby'
import { Footer } from '../common/footer'
import { Navigation } from '../common/navigation'
import { GlobalHelmet } from '../common/seo/globalHelmet'
import { CMSWrapper } from '../components/cmsWrapper'
import { StaticReferenceModules } from '../components/references/staticReferenceModules'
import {
  AREA_CARE_PATH,
  AREA_MFA_PATH,
  AREA_MTLA_PATH,
  AREA_MTRA_PATH,
} from '../config/constants'
import { StaticVideo } from '../components/staticSections'

export default function Index({ data }) {
  const indexPageReferences = data.allReference.nodes
  return (
    <CMSWrapper>
      <GlobalHelmet title="UKE jukebox" />
      <main id="start">
        <Navigation />
        <header className="bg-img-blue pt-5">
          <div className="row align-items-center pt-5">
            <div className="col-md-5 offset-md-1 col-12 text-center">
              <h1>
                <object
                  data="../img/jukebox-label.svg"
                  type="image/svg+xml"
                  width="360"
                  height="auto"
                  aria-label="Jukebox Logo"
                />
              </h1>
              <img
                src="/img/handlettering/handlettering-dein-leben-dein-soundtrack.svg"
                alt="Handlettering: Dein Leben, dein Soundtrack"
                title="Dein Leben dein Soundtrack"
              />
            </div>

            <div className="col-md-5 col-12 text-center">
              <img
                src="/img/illustration-jukebox.png"
                alt="Illustration: Jukebox"
              />
            </div>
          </div>
          <div className="col-md-10 offset-md-1 col12 text-center">
            <p>
              <strong>Moin und willkommen in unserer jukebox!</strong>
            </p>
            <p>
              Hier bist du genau richtig, wenn du die vielfältigen
              Einsatzmöglichkeiten im Universitätsklinikum Hamburg-Eppendorf
              (UKE) kennenlernen möchtest. Finde heraus, welcher Bereich am
              besten zu dir passt.
            </p>
            <p>
              <strong>
                Klicke einfach auf deine Berufsgruppe und stöbere dich durch.
              </strong>
            </p>
          </div>
        </header>

        <section className="text-center pb-0">
          <div className="row align-items-center">
            <div className="col-10 offset-1">
              <StaticVideo
                video={{
                  url: 'https://player.vimeo.com/video/953591459?h=b9ec315ba3',
                }}
              />
            </div>
          </div>
        </section>

        <section className="bg-img-yellow pb-0">
          <div className="row align-items-center text-center">
            <div className="col-md-5 offset-md-1 col-12 order-2 order-md-1">
              <img
                className="illustration mb-0"
                src="/img/pflege/illustration-pflege.png"
                alt="Illustration: Pfleger Duo"
              />
            </div>
            <div className="col-md-5 col-12 order-1 order-md-2">
              <h2>
                Entdecke jetzt die <strong>Pflege</strong> im UKE
              </h2>
              <Link
                className="button bg-yellow text-uppercase my-2"
                to={AREA_CARE_PATH}
              >
                Jetzt stöbern
              </Link>
            </div>
          </div>
        </section>

        <section className="bg-img-yellow pb-0">
          <div className="row align-items-center text-center">
            <div className="col-md-5 offset-md-1 col-12">
              <h2>
                Startet jetzt als <strong>MFA</strong> im UKE
              </h2>
              <Link
                className="button bg-yellow text-uppercase my-2"
                to={AREA_MFA_PATH}
              >
                Jetzt stöbern
              </Link>
            </div>
            <div className="col-md-5 col-12">
              <img
                className="illustration mb-0"
                src="/img/mfa/illustration-mfa.png"
                alt="Illustration: MFA Duo"
              />
            </div>
          </div>
        </section>

        <section className="bg-img-yellow pb-0">
          <div className="row align-items-center text-center">
            <div className="col-md-5 offset-md-1 col-12">
              <img
                className="illustration mb-0"
                src="/img/mtf/illustration-mtf.webp"
                alt="Illustration: MTF / MTF-A Duo"
              />
            </div>
            <div className="col-md-5 col-12">
              <h2>
                Taucht mit uns in die Welt der Funktionsdiagnostik ein als{' '}
                <strong>MTF / MTAF</strong>
              </h2>
              <a
                className="button bg-yellow text-uppercase my-2"
                href="https://jukebox.uke.de/station/herz-und-gefaessambulanz-und-ambulanz-fuer-herzinsuffizienz-herz-und-lungentransplantation-und-kunstherzsysteme"
              >
                Jetzt stöbern
              </a>
            </div>
          </div>
        </section>

        <section className="bg-img-yellow pb-0">
          <div className="row align-items-center text-center">
            <div className="col-md-5 offset-md-1 col-12 order-2 order-md-1">
              <h2>
                Erlebt mir uns die ganze Welt der Laboratoriumsanalytik als{' '}
                <strong>MTL / MTLA</strong>
              </h2>
              <Link
                className="button bg-yellow text-uppercase my-2"
                to={AREA_MTLA_PATH}
              >
                Jetzt stöbern
              </Link>
            </div>
            <div className="col-md-5 col-12 order-1 order-md-2">
              <img
                className="illustration mb-0"
                src="/img/mtla/illustration-mtla.png"
                alt="Illustration: MTL / MTLA Duo"
              />
            </div>
          </div>
        </section>

        <section className="bg-img-yellow pb-0 mb-5">
          <div className="row align-items-center text-center">
            <div className="col-md-5 offset-md-1 col-12">
              <img
                className="illustration mb-0"
                src="/img/mtra/illustration-mtra.png"
                alt="Illustration: MTR / MTRA Duo"
              />
            </div>
            <div className="col-md-5 col-12">
              <h2>
                Erlebt mir uns die ganze Welt der Radiologie als{' '}
                <strong>MTR / MTRA</strong>
              </h2>
              <Link
                className="button bg-yellow text-uppercase my-2"
                to={AREA_MTRA_PATH}
              >
                Jetzt stöbern
              </Link>
            </div>
          </div>
        </section>

        <StaticReferenceModules referencesData={indexPageReferences} />
        <Footer />
      </main>
    </CMSWrapper>
  )
}

export const query = graphql`
  query IndexReferencesQuery($referenceType: [String]) {
    allReference(
      filter: { types: { in: $referenceType }, isDraft: { eq: false } }
    ) {
      nodes {
        _id
        content {
          sections {
            _template
            bgColor
            grids {
              _template
              columns {
                label
                href
                align
                buttonBgColor
                target
                _template
                altText
                description
                descriptionAlignment
                showFrame
                filename
                listStyle
                text
                textAlign
                url
                images {
                  _template
                  altText
                  description
                  filename
                  showFrame
                }
              }
              itemAlignment
              marginType
            }
          }
        }
        mediaDir
        priority
        slug
        title
      }
    }
  }
`
